body {
  background-color: #d7fdf6;
  background-image: linear-gradient(0deg, #dfdc9e, #e2fffb);
  height: 100vh;
  font-family: courier;
  font-size: 20px;
}

.centered {
  text-align: center;
  justify-content: center;
  display: flex;
}

.avatar-container {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: inline-flex;
  overflow: hidden;
}

.myself {
  transition: transform .5s;
}

#me {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.a7ba6b2d.css.map */
